import React, { Component } from "react";

import { getChildQuestions } from './../../actions/formActions';
import { getUserData } from './../../actions/utility';
import store from './../../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Input,
    Label
} from "reactstrap";

class DateFieldComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateValue : ''
        };
    }

    componentDidMount() {
        var checkValue = localStorage.getItem('value_' + this.props.answer.questionid + "_" + this.props.answer.answerid);
        if(checkValue && (checkValue != "" && checkValue != null)) {
            this.setState({
                dateValue : checkValue
            }, () => {
                var userData = getUserData();
                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 10, userData.form_id, 0, this.state.dateValue); // get this answers sub questions if this is true
            })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {

    }

    checkChildQuestions = (e, questionid, answerid) => {
        console.log(e)
        this.setState({
            dateValue : e.target.value
        }, () => {
            var userData = getUserData();
            if(this.state.dateValue == '' || this.state.dateValue == undefined){

                this.props.getChildQuestions(false, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 10, userData.form_id, 0, ""); // get this answers sub questions if this is true

            }
            else{

                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 10, userData.form_id, 0, this.state.dateValue); // get this answers sub questions if this is true
            }
            
            this.updateLocalStorageAns(questionid, answerid, this.state.dateValue); // update storage for answes / values

        })
        
    }

    updateLocalStorageAns = (ques, ans, value) => {
        console.log(ans, ques, value);
        localStorage.setItem('value_' + ques + "_" + ans, value);
    }


    render() {

        const answer = this.props.answer;
        const key = this.props.index;

        return (
            <div className="DateFieldComponent">
                <Input
                    type="date"
                    name={'answer_' + answer.answerid}
                    id={'answer_' + answer.answerid}
                    value={this.state.dateValue}
                    onChange={e => this.checkChildQuestions(e, answer.questionid, answer.answerid)}
                    placeholder="date placeholder"
                />
            </div>
        );
        
    }

}

DateFieldComponent.propTypes = {
    getChildQuestions: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginResponse: state.formRed.loginResponse
})

export default connect(mapStatesToProps, { getChildQuestions })(DateFieldComponent);