import React, { Component } from "react";

import store from './../../store';
import PropTypes from 'prop-types';
import { sendLoginCredentials } from './../../actions/formActions';
import { validateEmailAddress, validatePhone } from './../../actions/utility';

import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup
} from "reactstrap";

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup : false,
            email : '',
            user_first : '',
            user_contact : '',
            formError : '',
            formIdHere : ''
        };
    }

    componentDidMount() {
        
        
        
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
        if (nextProps.showPopup !== false && this.props.showPopup != nextProps.showPopup) {

            var formId = nextProps.showPopup.split('/');
            this.setState({
                formIdHere : formId[0]
            }, () => {
                this.setState({
                    showPopup: true
                });
            })

        }

        if(this.props.loginResponse != nextProps.loginResponse) {

            if(nextProps.loginResponse != 'error' && nextProps.loginResponse.data.message == "Successfully Registered."){
                // localStorage.setItem('userData', JSON.stringify(nextProps.loginResponse.data.userData));
                this.setState({
                    showPopup : false
                })
            }
            else{
                this.setState({formError : 'Error in register. Please try again'})
            }

        }
        

    }

    toggleLoginModal = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    }

    submitLoginForm = () => {

        if(this.state.user_first == '' || this.state.user_contact == '' || this.state.email == ''){
            this.setState({
                formError : "All fields are required."
            })
        }
        else{
            if(validateEmailAddress(this.state.email)){

                if(validatePhone(this.state.user_contact)){
                    this.setState({
                        formError : ''
                    }, () => {
                        this.props.sendLoginCredentials(this.state.formIdHere, this.state.email, this.state.user_first, this.state.user_contact);
                    })
                }
                else{
                    this.setState({
                        formError : 'Phone number is not valid.'
                    })
                }

            }
            else{
                this.setState({
                    formError : 'Email is not valid.'
                })
            }
        }
    }


    render() {
        return (
            <div className="LoginComponent">

                <Modal isOpen={this.state.showPopup} centered={true} size="sm">
                    <ModalBody style={{padding: '20px 10px', background: '#101010', color: '#fff'}}>
                        <div>
                            <Container>
                                <Row>
                                    <Col>
                                        <h5 style={{margin: '10px 0 20px 0'}}>How can we reach you ?</h5>
                                    </Col>
                                    <Col md={{size : 12}}>
                                        <FormGroup style={{position: 'relative'}}>
                                            <Input style={{height: '50px'}} type="text" name="user_first" id="user_first" placeholder="Full Name" onChange={(evt) => {
                                                this.setState({ user_first: evt.target.value })
                                            }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size : 12}}>
                                        <FormGroup style={{position: 'relative'}}>
                                            <Input style={{height: '50px'}} type="text" name="user_contact" id="user_contact" placeholder="Phone" onChange={(evt) => {
                                                this.setState({ user_contact: evt.target.value })
                                            }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size : 12}}>
                                        <FormGroup style={{position: 'relative'}}>
                                            <Input style={{height: '50px'}} type="email" name="email" id="email" placeholder="Your email" onChange={(evt) => {
                                                this.setState({ email: evt.target.value })
                                            }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={{size : 12}}>
                                        <FormGroup>
                                            <Button onClick={this.submitLoginForm} block size="lg" color="warning">NEXT</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>   
                                        {
                                            this.state.formError && this.state.formError.length ?
                                            <p style={{color: 'red'}}>{ this.state.formError }</p> : null
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }

}

LoginComponent.propTypes = {
    sendLoginCredentials: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    loginResponse: state.formRed.loginResponse
})

export default connect(mapStatesToProps, { sendLoginCredentials })(LoginComponent);