import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoHere from "./../public/images/logo.png";
import bannerImage from "./../public/images/bannerImage.png";
import "../public/scss/FormComponent.scss";
import LoginComponent from "./login/LoginComponent";
import AnswerComponent from "./anwers/AnswerComponent";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import store from './../store';
import PropTypes from 'prop-types';
import { sendLoginCredentials, getParetQuestions, submitFormNow } from './../actions/formActions';
import { connect } from 'react-redux';
import { getUserData, logOut } from './../actions/utility';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  Container,
  Row,
  Media,
  Col,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  DropdownMenu
  // NavLink
} from "reactstrap";
import QuestionComponent from "./anwers/QuestionComponent";

class FormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorScheme : "blue",
      darkMode : "Light",
      colorMenuOpen : false,
      modeMenuOpen : false,
      showLoginPopup: false,
      formId: '',
      pageTitle : 'Form',
      userDropdownOpen : false,
      userName : '',
      checkLogoutDone : false,
      isSubmitted : false,
      formBanner : '',
      formLogo : '',
      formName : '',
      formDetail : '',
      formSuccessMessage: '',
      allParentQuestions : [],
      isFormIntegrated : false
    };

  }

  componentWillReceiveProps(nextProps, thisProps) {

    if(this.props.loginResponse != nextProps.loginResponse) {

      if(nextProps.loginResponse != 'error' && nextProps.loginResponse.data.message == "Successfully Registered."){
          var userData = nextProps.loginResponse.data.userData;
          localStorage.setItem('userData', '');
          localStorage.setItem('userData', JSON.stringify(userData));
          
          this.setState({
            userName : userData.user_name
          },
          () => {
            this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
          })

      }
    }

    if(this.props.parentQuestions != nextProps.parentQuestions) {
      
      try{

        var banner = nextProps.parentQuestions.data.testdata.form_banner;
        var logo = nextProps.parentQuestions.data.testdata.form_logo;
        var formName = nextProps.parentQuestions.data.testdata.testname;
        var formDetail = nextProps.parentQuestions.data.testdata.testdetail;
        var formSuccessMessage = nextProps.parentQuestions.data.testdata.success_message;
        
        if(!banner || banner == null && banner == ''){
          banner = '';
        }
        if(!logo || logo == null && logo == ''){
          logo = '';
        }
        if(!formName && formName == null && formName == ''){
          formName = '';
        }
        if(!formDetail && formDetail == null && formDetail == ''){
          formDetail = '';
        }
        if(!formSuccessMessage || formSuccessMessage == null && formSuccessMessage == ''){
          formSuccessMessage = 'For registering with us.';
        }
  
        this.setState({
          allParentQuestions : nextProps.parentQuestions.data.questions,
          formBanner : banner,
          formLogo : logo,
          formName : formName,
          formDetail : formDetail,
          formSuccessMessage : formSuccessMessage
        })

      }
      catch{

        console.log('error in data loading')

      }

    }

    if(this.props.submittedResponse != nextProps.submittedResponse){ // if form submitted successfully.
      this.setState({
        checkLogoutDone : true
      },
      () => {

        confirmAlert({
          title: 'Thank you.',
          message: this.state.formSuccessMessage,
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
              {
                  label: this.state.formId == "8711" ? 'Continue to Step 2' : 'Okay',
                  onClick: () => {
                    if(this.state.formId == "8711") {
                      localStorage.clear();
                      window.location = "https://lincolnmethod.com/careers/thanks-for-applying";
                    } else {
                      localStorage.clear();
                      window.location.reload();
                    }

                  }
              }
          ]
        })

      })
    }

  }

  componentWillMount() {
    var formId = this.props.match.params.formId;

    this.setState({
      formId : atob(formId)
    }, () => {
      
      var urlParams = new URLSearchParams(window.location.search);
      var nested = urlParams.get('nested');
      var email = urlParams.get('email');
      var phone = urlParams.get('phone');
      var name = urlParams.get('name');
      
      if(nested && email && phone && name) { // check if form type is nested or integrated with in iFrame
        console.log(this.state.formId, email, name, phone);
        this.setState({
          isFormIntegrated : true
        }, () => {
          this.props.sendLoginCredentials(this.state.formId, email, name, phone);
        })
      }
      else{
        
        if(localStorage.getItem('userData') && localStorage.getItem('userData') != ''){

          var userData = JSON.parse(localStorage.getItem('userData'));
          
          this.setState({
            userName : userData.user_name
          },
          () => {
            // this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
            this.props.sendLoginCredentials(this.state.formId, userData.user_email, userData.user_name, userData.contact);
          })
          
        }
        else{
          this.setState({
            showLoginPopup : this.state.formId + '/' + Math.random()
          })
        }

      }

      
      if(this.state.formId == "8693"){ // if it's integrated form for talentMap dashboard
        this.setState({
          isFormIntegrated : true,
          darkMode : 'Dark DarkForTalentmap1',
          colorScheme : 'yellow',
          checkLogoutDone : true
        })
      }
      
      if(this.state.formId == "8692"){
        this.setState({
          isFormIntegrated : true,
          darkMode : 'Dark DarkForTalentmap2',
          colorScheme : 'green',
          checkLogoutDone : true
        })
      }

      if(this.state.formId == "8702"){
        this.setState({
          isFormIntegrated : true,
          checkLogoutDone : true
        })
      }
        
    })
  }

  
  componentDidMount() {

    window.onbeforeunload = function(){
      if(!this.state.checkLogoutDone){
        return false;
      }
    }.bind(this);

    document.title = this.state.pageTitle;

    // var formId = this.props.match.params.formId;

    // this.setState({
    //   formId : atob(formId)
    // }, () => {


      // var urlParams = new URLSearchParams(window.location.search);
      // var nested = urlParams.get('nested');
      // var email = urlParams.get('email');
      // var phone = urlParams.get('phone');
      // var name = urlParams.get('name');
      
      // if(nested && email && phone && name) { // check if form type is nested or integrated with in iFrame
      //   console.log(this.state.formId, email, name, phone);
      //   this.setState({
      //     isFormIntegrated : true
      //   }, () => {
      //     this.props.sendLoginCredentials(this.state.formId, email, name, phone);

      //     if(this.state.formId == "8693" || this.state.formId == "8692"){ // if it's integrated form for talentMap dashboard
      //       this.setState({
      //         darkMode : 'Dark',
      //         checkLogoutDone : true
      //       })
      //     }

      //   })
      // }
      // else{
        
      //   if(localStorage.getItem('userData') && localStorage.getItem('userData') != ''){

      //     var userData = JSON.parse(localStorage.getItem('userData'));
          
      //     this.setState({
      //       userName : userData.user_name
      //     },
      //     () => {
      //       // this.props.getParetQuestions(this.props.match.params.formId, userData.id, userData.engineid, userData.user_type); // calls parent questions
      //       this.props.sendLoginCredentials(this.state.formId, userData.user_email, userData.user_name, userData.contact);
      //     })
          
      //   }
      //   else{
      //     this.setState({
      //       showLoginPopup : this.state.formId + '/' + Math.random()
      //     })
      //   }

      // }
        
    // })
    
    
  }

  componentWillUnmount() {
    
  }

  toggleUserDrop = () => {
    // clear userData from storage and reload the page
    var logOutMessage = "";
    if(!this.state.isSubmitted){
      logOutMessage = "you have not submitted form yet.";
    }
    confirmAlert({
        title: 'Are you sure ?',
        message: 'You want to logout. ' + logOutMessage,
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'YES',
                onClick: () => {
                  this.setState({
                    checkLogoutDone : true
                  },
                  () => {
                    // localStorage.setItem('userData', '');
                    localStorage.clear();
                    window.location.reload();
                  })
                }
            },
            {
                label: 'NO',
                onClick: () => {
                    console.log('no.');
                }
            }
        ]
    })
  }

  DoneForm = () => {

      confirmAlert({
        title: 'Quick confirmation',
        message: 'Ready to submit your form?',
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'YES',
                onClick: () => {
                  this.setState({
                    isSubmitted : true
                  }, () => {

                    var userData = getUserData();
                    this.props.submitFormNow(userData.form_id, userData.id); // calls submit form action

                  })
                }
            },
            {
                label: 'NO',
                onClick: () => {
                    console.log('not submitted.');
                }
            }
        ]
    })

  }


  render() {
    return (
      <div className={"MainFormComponent " + this.state.colorScheme + " " + this.state.darkMode}>

        <LoginComponent showPopup={this.state.showLoginPopup} />

        <div className="bannerImage" style={{ backgroundImage: this.state.formBanner !== "" ? "url("+ this.state.formBanner +")" : null, color: '#fff' }}>
          <Container style={{textAlign: 'left'}}>
            {
              this.state.userName != '' ?
              
                <Row style={{padding: '3vh 0', display: this.state.isFormIntegrated ? 'none' : 'inherit'}}>
                  <Col style={{textAlign: 'right'}}>

                    <Dropdown style={{display: 'inline-block', marginRight: '15px'}} size="sm" isOpen={this.state.modeMenuOpen} toggle={e => this.setState({modeMenuOpen: !this.state.modeMenuOpen})}>
                      <DropdownToggle color="info" caret>
                        {this.state.darkMode}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => this.setState({ darkMode: 'Light' })}>Light Mode</DropdownItem>
                        <DropdownItem onClick={e => this.setState({ darkMode: 'Dark' })}>Dark Mode</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                    <Dropdown style={{display: 'inline-block', marginRight: '15px'}} size="sm" isOpen={this.state.colorMenuOpen} toggle={e => this.setState({colorMenuOpen: !this.state.colorMenuOpen})}>
                      <DropdownToggle color="info" caret>
                        {this.state.colorScheme}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => this.setState({ colorScheme: 'blue' })}>Blue</DropdownItem>
                        <DropdownItem onClick={e => this.setState({ colorScheme: 'green' })}>Green</DropdownItem>
                        {/* <DropdownItem onClick={e => this.setState({ colorScheme: 'yellow' })}>Yellow</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>

                    <Button style={{marginRight: '12px'}} disabled size="sm" color="secondary">{this.state.userName}</Button>
                    <Button color="warning" size="sm" onClick={this.toggleUserDrop}>Logout</Button>

                  </Col>
                </Row>
                
                : null
            }
              <Row>
                <Col md={{ size: 6, offset: 0 }}>
                  {
                    this.state.formLogo != '' ?
                      <NavbarBrand style={{margin: '0'}} >
                        <img src={this.state.formLogo} alt="" width="160" />
                      </NavbarBrand> : null
                  }
                  {
                    this.state.formName != '' ?
                      <h2 style={{margin: '4vh 0 2.5vh 0'}}>{this.state.formName}</h2> : null
                  }
                  {
                    this.state.formDetail != '' ?
                      <p style={{marginBottom: '8vh'}} dangerouslySetInnerHTML={{__html: this.state.formDetail}}></p> : null
                  }
                  
                </Col>
              </Row>
          </Container>
        </div>

        <div style={{padding: '5vh 0'}}>
          <Container style={{textAlign: 'left', fontSize: '14px'}}>
            <Row>
              <Col md={{ size: 12 }}>
                {
                  this.state.allParentQuestions.length > -1 && this.state.allParentQuestions.map((question, key) => {
                    // console.log(question);
                    return (
                      <QuestionComponent key={key} question={question} />
                    )
                  })
                }

              </Col>
              {
                this.state.userName != '' ?
                  <Col md={{size: 12}} style={{textAlign: 'right'}}>
                    <Button color="warning" onClick={this.DoneForm}>Submit</Button>
                  </Col>
                : null
              }
            </Row>
          </Container>
        </div>

      </div>
    );
  }
}

FormComponent.propTypes = {
  sendLoginCredentials: PropTypes.func.isRequired,
  getParetQuestions: PropTypes.func.isRequired,
  submitFormNow: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  loginResponse: state.formRed.loginResponse,
  parentQuestions: state.formRed.parentQuestions,
  submittedResponse: state.formRed.submittedResponse
})

export default connect(mapStateToProps, { sendLoginCredentials, getParetQuestions, submitFormNow })(FormComponent);
