import React, { Component } from "react";

import { getChildQuestions } from './../../actions/formActions';
import { getUserData } from './../../actions/utility';
import store from './../../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Input,
    Label
} from "reactstrap";

class OneLineComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isValue : ''
        };
    }

    componentDidMount() {
        var checkValue = localStorage.getItem('value_' + this.props.answer.questionid + "_" + this.props.answer.answerid);
        if(checkValue && (checkValue != "" && checkValue != null)) {
            this.setState({
                isValue : checkValue
            }, () => {
                var userData = getUserData();
                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 3, userData.form_id, 0, this.state.isValue); // get this answers sub questions if this is true
            })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {

    }

    // checkChildQuestions = (e, check) => {
    //     var userData = getUserData();
            
    //     if(check == 'paste'){
            
    //         this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 3, userData.form_id, 0, this.state.isValue); // get this answers sub questions if this is true

    //     }
    //     else{
    //         if(e.charCode){
    //             if(this.state.isValue.length > -1 && this.state.isValue.length < 1){
                
    //                 this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 3, userData.form_id, 0, this.state.isValue); // get this answers sub questions if this is true
    //             }
    //         }
    //     }
        
    // }

    updateValue = (e, questionid, answerid) => {

        var userData = getUserData();
        
        if(this.state.isValue == '' || this.state.isValue.length < 1){
            this.props.getChildQuestions(false, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 3, userData.form_id, 0, ""); // get this answers sub questions if this is true
        }
        else{
            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 3, userData.form_id, 0, this.state.isValue); // get this answers sub questions if this is true
        }

        this.updateLocalStorageAns(questionid, answerid, this.state.isValue); // update storage for answes / values

    }

    updateLocalStorageAns = (ques, ans, value) => {
        console.log(ans, ques, value);
        localStorage.setItem('value_' + ques + "_" + ans, value);
    }


    render() {

        const answer = this.props.answer;
        const key = this.props.index;

        return (
            <div className="OneLineComponent">
                {/* <Input type="text" name={'answer_' + answer.answerid} id={'answer_' + answer.answerid} value={this.state.isValue} onChange={e => this.updateValue(e)} onPaste={e => this.checkChildQuestions(e, 'paste')} onKeyPress={e => this.checkChildQuestions(e, 'press')} /> */}
                <Input type="text" name={'answer_' + answer.answerid} id={'answer_' + answer.answerid} value={this.state.isValue} onBlur={e => this.updateValue(e, answer.questionid, answer.answerid)} onChange={e => 
                this.setState({
                    isValue : e.target.value
                })} />
            </div>
        );
        
    }

}

OneLineComponent.propTypes = {
    getChildQuestions: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginResponse: state.formRed.loginResponse
})

export default connect(mapStatesToProps, { getChildQuestions })(OneLineComponent);