import React, { Component } from "react";

import { getChildQuestions } from './../../actions/formActions';
import { getUserData } from './../../actions/utility';
import store from './../../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery'; 

import {
    CustomInput,
    Label
} from "reactstrap";

class MultiChoiceComponentSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCheckedState : false
        };
    }

    componentDidMount() {

        var answer = this.props.answer;
        
        // using jQuery event handeling for radio button for single options
        $('input[type=radio][name=answer_'+ answer.questionid +']').change(function(e) {
            var isChecked = $('input[type=radio][id=answer_' + answer.answerid + '_' + answer.questionid +']').is(':checked');
            // console.log(e.target.value);
            var userData = getUserData();
                
            if (isChecked) {

                $(this).prop('checked', true);

                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on"); // get this answers sub questions if this is true

                this.updateLocalStorageAns(answer.questionid, answer.answerid, true); // update storage for answes / values

            }
            else {

                $(this).prop('checked', false);

                // alert("Transfer Thai Gayo");
                this.props.getChildQuestions(false, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 0, "on"); // get this answers sub questions if this is true

                this.updateLocalStorageAns(answer.questionid, answer.answerid, false); // update storage for answes / values

            }
        }.bind(this));


        var checkValue = localStorage.getItem('value_' + answer.questionid + "_" + answer.answerid);
        if(checkValue && (checkValue == true || checkValue == 'true')) {
            
            $('input[type=radio][id=answer_' + answer.answerid + '_' + answer.questionid +']').prop('checked', true);

            var userData = getUserData();
            
            this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, answer.questionid, answer.answerid, userData.user_token, 2, userData.form_id, 1, "on"); // get this answers sub questions if this is true
        
        }
        
    }

    updateLocalStorageAns = (ques, ans, value) => {
        console.log(ans, ques, value);
        localStorage.setItem('value_' + ques + "_" + ans, value);
    }

    componentWillReceiveProps(nextProps, thisProps) {

    }


    render() {

        const answer = this.props.answer;
        // console.log(answer)
        const key = this.props.index;

        return (
            <div className="MultiChoiceComponentSingle">
                <CustomInput type="radio" name={'answer_' + answer.questionid} value={'answer_' + answer.answerid + '_' + answer.questionid} id={'answer_' + answer.answerid + '_' + answer.questionid} />
                <Label for={'answer_' + answer.answerid + '_' + answer.questionid} dangerouslySetInnerHTML={{__html: answer.answer}}></Label>
            </div>
        );
        
    }

}

MultiChoiceComponentSingle.propTypes = {
    getChildQuestions: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginResponse: state.formRed.loginResponse
})

export default connect(mapStatesToProps, { getChildQuestions })(MultiChoiceComponentSingle);