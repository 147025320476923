import React, { Component } from "react";

import store from './../../store';
import PropTypes from 'prop-types';
// import { sendLoginCredentials } from './../../actions/formActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import './../../public/scss/AnswerComponent.scss';
import MultiChoiceComponent from './MultiChoiceComponent';
import MultiChoiceComponentSingle from './MultiChoiceComponentSingle';
import OneLineComponent from './OneLineComponent';
import ParagraphComponent from './ParagraphComponent';
import DateFieldComponent from './DateFieldComponent';
import TimeFieldComponent from './TimeFieldComponent';

import QuestionComponent from './QuestionComponent';

// import { CSSTransitionGroup } from 'react-transition-group' // ES6



import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Media,
    Badge,
    Spinner,
    Input,
    UncontrolledCollapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup,
    CustomInput,
    Label,
    Collapse
} from "reactstrap";

class AnswerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answerType : '',
            collapse : false,
            answers : [],
            childQuestions : []
        };
    }

    componentDidMount() {
        
        this.setState({
            answerType: this.props.answerData.type,
            answers: this.props.answerData.answers
        }, () => {
            // console.log(this.state.answerType, this.state.answers)
            this.setState({
                collapse : true
            })
        })
        
    }

    componentWillReceiveProps(nextProps, thisProps) {

        if(this.props.childQuestions != nextProps.childQuestions) {
            var childQues = this.state.childQuestions;
            

            if(nextProps.childQuestions.data.status == 200){
                if(nextProps.childQuestions.data.data.questions.length > -1){

                    // console.log('abcd')
                    
                    if(Object.keys(childQues).length > 0){
                        
                        // console.log('xyz', Object.keys(childQues).length)
                        var flag = true, length = 1;
                        for(var key in childQues) {
                            var condition = childQues[key].questionId == nextProps.childQuestions.questionId && childQues[key].answerId == nextProps.childQuestions.answerId;
                            // console.log(condition)
                            if(condition) {
                                flag = false;
                            }

                            if(flag == true && length == Object.keys(childQues).length){
                                childQues.push({
                                    questionId : nextProps.childQuestions.questionId,
                                    answerId : nextProps.childQuestions.answerId,
                                    questions : nextProps.childQuestions.data.data.questions
                                });
                            }

                            length++;
                        }
                        
                    }
                    else{
                        // console.log('123')
                        childQues.push({
                            questionId : nextProps.childQuestions.questionId,
                            answerId : nextProps.childQuestions.answerId,
                            questions : nextProps.childQuestions.data.data.questions
                        });
                    }

                    this.setState({
                        childQuestions : childQues
                    },
                    () => {
                        console.log(this.state.childQuestions)
                        // this.setState({
                        //     collapse : true
                        // })
                    })
                    
                }
            }
            else{
                if(nextProps.childQuestions.data && nextProps.childQuestions.data.indexOf('remove') > -1){
                    var childQues = this.state.childQuestions;
    
                    for(var key in childQues) {
                        var condition = childQues[key].questionId == nextProps.childQuestions.questionId && childQues[key].answerId == nextProps.childQuestions.answerId;
                        if(condition){
                            // childQues.splice(key, 1) // removes the unchecked option
                            childQues[key].questionId = 0;
                            childQues[key].answerId = 0;
                            childQues[key].questions = [];
    
                            this.setState({
                                childQuestions : childQues
                            },
                            () => {
                                console.log(this.state.childQuestions)
                                // this.setState({
                                //     collapse : false
                                // })
                            })
    
                        }
                    }
                }
            }
            
        }

    }


    render() {

        // console.log(this.state.childQuestions)
        
        return (
            <div className="AnswerComponent">

                {/* <Form> */}
                    <FormGroup>

                    {
                    
                        this.state.answers && this.state.answers.length && this.state.answers.map((answer, key) => {
                            // const childQuestions = this.state.childQuestions[key];
                            // var sideColor = "";
                            // this.state.collapse ?
                            //     sideColor = "rgba(0, 165, 173, 1)"
                            // :
                            //     sideColor = "transparent" ;
                            return (
                                
                                <Collapse  key={key} isOpen={this.state.collapse}>

                                {/* <div> */}
                                    {
                                        this.state.answerType == '1' ?
                                            <div>
                                                <MultiChoiceComponent index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    
                                    {
                                        this.state.answerType == '2' ?
                                            <div>
                                                <MultiChoiceComponentSingle index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    
                                    {
                                        this.state.answerType == '3' ?
                                            <div>
                                                <OneLineComponent index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    
                                    {
                                        this.state.answerType == '4' ?
                                            <div>
                                                <ParagraphComponent index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    
                                    {
                                        this.state.answerType == "9" ?
                                            <div>
                                                <TimeFieldComponent index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    
                                    {
                                        this.state.answerType == "10" ?
                                            <div>
                                                <DateFieldComponent index={key} answer={answer} />
                                            </div>
                                        : null
                                    }
                                    

                                    {
                                        this.state.childQuestions && this.state.childQuestions.length > -1 && this.state.childQuestions.map((c_question, key) => 
                                            // console.log(c_question)
                                            c_question.questionId == answer.questionid && c_question.answerId == answer.answerid ?
                                            // return (<p>asdasdasd</p>)
                                            // <p>{c_question.answerId}</p>
                                            // console.log(c_question.questions)
                                                c_question.questions.map((sub_question, key2) => 
                                                    // console.log(sub_question)
                                                    // return (
                                                        // <p key={key2}>{sub_question.questionid}</p>
                                                        <QuestionComponent key={key2} question={sub_question} />
                                                    // )
                                                )

                                            : null
                                                
                                        )
                                            
                                    }

                                {/* </div> */}
                                </Collapse>
                            )
                        })

                    }
                    </FormGroup>
                {/* </Form> */}

            </div>
        );
    }

}

AnswerComponent.propTypes = {
    // sendLoginCredentials: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    childQuestions: state.formRed.childQuestions
})

export default connect(mapStatesToProps, {  })(AnswerComponent);