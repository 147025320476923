import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

//importing component for website
import FormComponent from "./components/FormComponent";


function App() {

  return (
    
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            
            <Route exact path="/:formId" component={FormComponent} />
            {/* <Route path="/form:id" component={FormComponent} /> */}

          </Switch>
        </div> 
      </Router>
    </Provider>
  );

}

export default App;