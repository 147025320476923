import React, { Component } from "react";

import { getChildQuestions } from './../../actions/formActions';
import { getUserData, isMobileBrowser } from './../../actions/utility';
import store from './../../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Input,
    Label
} from "reactstrap";

class TimeFieldComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeValue : ''
        };
    }

    componentDidMount() {
        var checkValue = localStorage.getItem('value_' + this.props.answer.questionid + "_" + this.props.answer.answerid);
        if(checkValue && (checkValue != "" && checkValue != null)) {
            this.setState({
                timeValue : checkValue
            }, () => {
                var userData = getUserData();
                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 9, userData.form_id, 0, this.state.timeValue); // get this answers sub questions if this is true
            })
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        
    }

    checkChildQuestions = (e, questionid, answerid) => {
        console.log(e)
        this.setState({
            timeValue : e.target.value
        }, () => {

            var userData = getUserData();
            if(this.state.timeValue == '' || this.state.timeValue == undefined){

                this.props.getChildQuestions(false, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 9, userData.form_id, 0, ""); // get this answers sub questions if this is true

            }
            else{

                this.props.getChildQuestions(true, btoa(userData.form_id), userData.id, userData.engineid, userData.user_type, this.props.answer.questionid, this.props.answer.answerid, userData.user_token, 9, userData.form_id, 0, this.state.timeValue); // get this answers sub questions if this is true
            }

            this.updateLocalStorageAns(questionid, answerid, this.state.timeValue); // update storage for answes / values

        })
        
    }

    updateLocalStorageAns = (ques, ans, value) => {
        console.log(ans, ques, value);
        localStorage.setItem('value_' + ques + "_" + ans, value);
    }


    render() {

        const answer = this.props.answer;
        const key = this.props.index;

        return (
            <div className="TimeFieldComponent">
                
                {/* {
                    !isMobileBrowser() ?
                    
                        <ClockPicker
                            hours={this.state.hours}
                            minutes={this.state.minutes}
                            disabled={false}
                            placement="right"
                            addonBefore={}
                            onChange={(hours, minutes) => this.setState({ hours, minutes })}
                        />

                    :  */}
                        <Input
                            type="time"
                            name={'answer_' + answer.answerid}
                            id={'answer_' + answer.answerid}
                            value={this.state.timeValue}
                            onChange={e => this.checkChildQuestions(e, answer.questionid, answer.answerid)}
                            placeholder="date placeholder"
                        />
                {/* } */}

            </div>
        );
        
    }

}

TimeFieldComponent.propTypes = {
    getChildQuestions: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // loginResponse: state.formRed.loginResponse
})

export default connect(mapStatesToProps, { getChildQuestions })(TimeFieldComponent);